<core-sidebar-wrapper>
  <a [routerLink]="'/construction/projects'" title="Terabase" class="sidenav-logo">
    <img src="../../../../../assets/images/logo/logo-white.svg" alt="Terabase" />
  </a>
  <nav-items-container>
    <div class="separator"></div>
    <nav-items-group-container>
      <nav-items-group>
        <nav-item icon="ci-grid-32" [haveSubnavigation]="true" [subnavigationOpened]="true" navTitle="Projects">
          <div class="grid-item-12">
            <div class="subnav-heading">
              <span class="tb-primary">Projects</span>
              <div
                *ngIf="userRoleEnum.SuperAdmin === userRole"
                [routerLink]="['/construction', 'projects', 'create']"
                class="tb-body-secondary flex-rec"
                style="color: white"
              >
                <i class="ci-plus-16 mr-1" style="font-size: 16px"></i>Create New Project
              </div>
            </div>
            <div class="separator"></div>
            <div
              class="subnav-project mt-1 mb-1"
              [routerLink]="['/construction', 'projects']"
              [routerLinkActive]="['active-nav-item']"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <div class="title">
                <label class="mat-subheading-1">
                  <span>Portfolio</span>
                </label>
                <span class="mat-caption"> Portfolio dashboard overview </span>
              </div>
            </div>
            <div class="separator"></div>
            <nav-items-group-container>
              <nav-items-group>
                <div class="pinned">
                  <div *ngFor="let project of pinnedProjects">
                    <div
                      class="subnav-project"
                      [class.disabled-navigation]="!project.url"
                      [routerLinkActive]="['active-nav-item']"
                    >
                      <div class="title" [routerLink]="project.url" [queryParams]="project.queryParams">
                        <label class="mat-subheading-1">
                          <span>{{ project.project.project_name }}</span>
                        </label>
                        <span class="mat-caption">
                          <span>
                            <span>{{ project.project.location }}</span>
                            <i class="ci-pin-24" style="font-size: 16px"></i>
                          </span>
                        </span>
                      </div>
                      <label class="styled-checkbox-container pin-section pl-1">
                        <input
                          type="checkbox"
                          name="{{ project._id }}"
                          id="pinned-{{ project._id }}"
                          [checked]="true"
                        />
                        <i
                          class="ci-star-24-f selected"
                          tbsThrottleClick
                          (throttleClick)="onPinProject(project, false)"
                        ></i>
                      </label>
                    </div>
                  </div>
                </div>
              </nav-items-group>
              <mat-divider *ngIf="pinnedProjects.length > 0 && unpinnedProjects.length > 0"></mat-divider>
              <div class="separator" *ngIf="pinnedProjects.length > 0 && unpinnedProjects.length > 0"></div>
              <nav-items-group>
                <div class="unpinned">
                  <div *ngFor="let project of unpinnedProjects">
                    <div
                      class="subnav-project"
                      [class.disabled-navigation]="!project.url"
                      [routerLinkActive]="['active-nav-item']"
                    >
                      <div class="title" [routerLink]="project.url" [queryParams]="project.queryParams">
                        <label class="mat-subheading-1">
                          <span>{{ project.project.project_name }}</span>
                        </label>
                        <span class="mat-caption">
                          <span>
                            <span>{{ project.project.location }}</span>
                          </span>
                          <span class="text-icon right">
                            <i class="ci-pin-24" style="font-size: 16px"></i>
                          </span>
                        </span>
                      </div>
                      <label class="styled-checkbox-container pin-section pl-1">
                        <input type="checkbox" name="{{ project._id }}" id="{{ project._id }}" [checked]="false" />
                        <i class="ci-star-24" tbsThrottleClick (throttleClick)="onPinProject(project, true)"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </nav-items-group>
            </nav-items-group-container>
          </div>
        </nav-item>
        <nav-item
          *ngIf="userRoleEnum.SuperAdmin === userRole"
          icon="ci-gear-32"
          navTitle="Configuration"
          [routerLink]="['/construction', 'configuration']"
        >
        </nav-item>
        <nav-item *ngIf="userRoleEnum.SuperAdmin !== userRole" icon="ci-group-32" navTitle="Company" [inactive]="true">
        </nav-item>
      </nav-items-group>
    </nav-items-group-container>
  </nav-items-container>
  <nav-items-container style="margin-top: auto">
    <nav-items-group-container>
      <nav-items-group>
        <div class="nav-item profile-wrapper">
          <span class="nav-link avatar">
            <core-image
              class="profile-picture"
              [imageId]="profilePicture"
              [size]="profilePicture ? 'contain' : '20px auto'"
              [dimensions]="{
                width: '32px',
                height: '32px'
              }"
            ></core-image>
            <a (click)="openUsersProfile()" class="inline-link">
              <span class="profile-name mat-subheading-1" [title]="userName">
                {{ userName }}
              </span>
            </a>
            <span class="icon">
              <i class="ci-walk-through-door-24" (click)="logout()" title="Log Out"></i>
            </span>
          </span>
        </div>
      </nav-items-group>
    </nav-items-group-container>
  </nav-items-container>
</core-sidebar-wrapper>
