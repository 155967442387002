function composePermission(module: string, context: string, action: string): string {
  return `[${module}]${context}:${action}`;
}

export class PermissionCollection {
  //Contacts page
  public static canViewInivites = composePermission('Core', 'Contacts', 'canViewInivites');

  //Responsibility page
  public static setProcurementCompanyType = composePermission('Core', 'Responsibility', 'setCompanyType');

  //About page
  public static uploadImage = composePermission('Core', 'About', 'uploadImage');
  public static deleteImage = composePermission('Core', 'About', 'deleteImage');
  public static addHeadline = composePermission('Core', 'About', 'addHeadline');
  public static uploadLargerImage = composePermission('Core', 'About', 'uploadLargerImage');
  public static deleteLargerImage = composePermission('Core', 'About', 'deleteLargerImage');
  public static addDescription = composePermission('Core', 'About', 'addDescription');
  public static addWebsite = composePermission('Core', 'About', 'addWebsite');
  public static verifySupplier = composePermission('Core', 'About', 'verifySupplier');
  public static addProductionCapacity = composePermission('Core', 'About', 'addProductionCapacity');
  public static addTotalPipeline = composePermission('Core', 'About', 'addTotalPipeline');
  public static addKeyManLocation = composePermission('Core', 'About', 'addKeyManLocation');
  public static addGeoFocus = composePermission('Core', 'About', 'addGeoFocus');
  public static submitBidToPlatform = composePermission('Core', 'About', 'submitBidToPlatform');
  public static createCompanyProfile = composePermission('Core', 'About', 'createCompanyProfile');
  public static enableDisableCompanyProfile = composePermission('Core', 'About', 'enableDisableCompanyProfile');

  //Company page
  public static invitePerson = composePermission('Core', 'Company', 'invitePerson');

  //UserForm component
  public static userFormUploadImage = composePermission('Core', 'UserForm', 'uploadImage');
  public static userFormViewFirstLastName = composePermission('Core', 'UserForm', 'viewFirstLastName');
  public static userFormStartChat = composePermission('Core', 'UserForm', 'startChat');
  public static userFormEditDescription = composePermission('Core', 'UserForm', 'editDescription');
  public static userFormEditVideoIntro = composePermission('Core', 'UserForm', 'editVideoIntro');
  public static userFormEditSalesRegion = composePermission('Core', 'UserForm', 'editSalesRegion');
  public static userFormViewKeyAccountManager = composePermission('Core', 'UserForm', 'viewKeyAccountManager');
  public static userFormEditPhoneNumber = composePermission('Core', 'UserForm', 'editPhoneNumber');
  public static userFormEditRole = composePermission('Core', 'UserForm', 'editRole');
  public static userFormViewRole = composePermission('Core', 'UserForm', 'viewRole');
  public static userFormSaveProfile = composePermission('Core', 'UserForm', 'saveProfile');
  public static userFormDisableUser = composePermission('Core', 'UserForm', 'disableUser');
  public static userFormMakeMyKeyAccountManager = composePermission('Core', 'UserForm', 'makeMyKeyAccountManager');

  //Configuration page
  public static saveProjectConfiguration = composePermission(
    'Construction',
    'ConfigurationPage',
    'saveProjectConfiguration',
  );
  public static changeProjectEpc = composePermission('Construction', 'ConfigurationPage', 'changeProjectEpc');
  public static editDeleteDronOrtho = composePermission('Construction', 'Create', 'editDeleteDronOrtho');
  public static editDeleteDronePano = composePermission('Construction', 'Create', 'editDeleteDronePano');

  // Team members component
  public static addTeamMember = composePermission('Construction', 'TeamMemberTable', 'saveProjectConfiguration');
  public static editTeamMember = composePermission('Construction', 'TeamMemberTable', 'saveProjectConfiguration');

  // Inventory & Logistic inputs configuration
  public static logisticsInputConfiguration = composePermission('Construction', 'Logistic', 'inputConfiguration');
  public static shipmentListView = composePermission('Construction', 'Logistic', 'shipmentListView');
  public static logisticsDashboard = composePermission('Construction', 'Logistic', 'Dashboard');
  public static shipmentListViewFlagAction = composePermission(
    'Construction',
    'Logistic',
    'shipmentListViewFlagAction',
  );
  public static deliveryVsMsaReport = composePermission('Construction', 'Logistic', 'deliveryVsMsaReport');
  public static deliveryVsEpcReport = composePermission('Construction', 'Logistic', 'deliveryVsEpcReport');
  public static deliveryVsMsaAndEpcReport = composePermission('Construction', 'Logistic', 'deliveryVsMsaAndEpcReport');
  public static progressTrackingReport = composePermission('Construction', 'Logistic', 'seeProgressTrackingReport');
  public static inventoryTrackingReport = composePermission('Construction', 'Logistic', 'seeProgressTrackingReport');
  public static addEditCommentsToLogisticsShipment = composePermission(
    'Construction',
    'Logistic',
    'addEditCommentsToLogisticsShipment',
  );

  public static canSeeOmega = composePermission('Construction', 'Logistic', 'canSeeOmega');

  //Projects
  public static canSeeRecenter = composePermission('Construction', 'Projects', 'canSeeRecenter');
  public static canEditProject = composePermission('Construction', 'Projects', 'canEditProject');
  public static navigateToProjectSetup = composePermission('Construction', 'Projects', 'navigateToProjectSetup');

  // User Can See Maps
  public static canSeeADReviewMap = composePermission('Construction', 'Projects', 'canSeeADReviewMap');
  public static canSeeApprovalsMap = composePermission('Construction', 'Projects', 'canSeeApprovalsMap');
  public static canSeeQCIssuesMap = composePermission('Construction', 'Projects', 'canSeeQCIssuesMap');
  public static canSeeOverviewMap = composePermission('Construction', 'Projects', 'canSeeOverviewMap');
  public static canSeeTerafabMap = composePermission('Construction', 'Projects', 'canSeeTerafabMap');

  public static canEditGeneral = composePermission('Construction', 'Projects', 'canEditGeneral');
  public static canEditFeatures = composePermission('Construction', 'Projects', 'canEditFeatures');
  public static canEditMilestones = composePermission('Construction', 'Projects', 'canEditMilestones');
  public static canEditReferenceLayers = composePermission('Construction', 'Projects', 'canEditReferenceLayers');
  public static canEditEquipmentSpecs = composePermission('Construction', 'Projects', 'canEditEquipmentSpecs');
  public static canAddEditWorkPackages = composePermission('Construction', 'Projects', 'canAddEditWorkPackages');
  public static canAccessOrthoImagery = composePermission('Construction', 'Projects', 'canAccessOrthoImagery');
  public static canEditMvCircuitStatus = composePermission('Construction', 'Projects', 'canEditMvCircuitStatus');
  public static canAddEditRovers = composePermission('Construction', 'Projects', 'canAddEditRovers');
  public static canEditWbsElements = composePermission('Construction', 'Projects', 'canEditWbsElements');
  public static canEditKPIs = composePermission('Construction', 'Projects', 'canEditKPIs');
  public static canEditSchedule = composePermission('Construction', 'Projects', 'canEditSchedule');
  public static canSeeSchedule = composePermission('Construction', 'Projects', 'canSeeSchedule');
  public static canAccessEditProject = composePermission('Construction', 'Projects', 'canAccessEditProject');
  public static canVerifyCircuitStatus = composePermission('Construction', 'Projects', 'canVerifyCircuitStatus');
  public static canOpenConstructionProject = composePermission(
    'Construction',
    'Projects',
    'canOpenConstructionProject',
  );

  public static canAddNewConstructionProject = composePermission(
    'Construction',
    'Projects',
    'canAddNewConstructionProject',
  );

  public static canSeeDigitalTwin = composePermission('Construction', 'Projects', 'canSeeDigitalTwin');
}
