import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/@core/services/rest/api.service';
import { environment } from 'src/environments/environment';
import {
  ConstructionProjectLoadModel,
  GeoProjectModel,
  GeoProjectResponse,
} from '../models/construction-project.model';
import {
  AreaModel,
  MVFeeder,
  TrackerTypeModel,
  TableTypeModel,
  TrackerModel,
  RoadsResponseData,
  TBResponse,
  FenceData,
  UpdatedMVFeederPayload,
  SiteDataType,
  AcBlockWithObservation,
} from '../models/wbs-elements.model';
import { Moment } from 'moment';
import { MapLayerEnum } from '@construction/construction.enums';
import { ProgressRefreshType } from '@construction/modules/project-edit/projects.types';
import { LatestInstallationDateType } from '@construction/construction.types';
import {
  CSVExportDataType,
  ChartDataParams,
  ChartDataRequestParams,
} from '@construction/modules/management/management.types';
import { ChartDataRequestParamEnum } from '@construction/modules/management/management.enums';

export interface IUpdateStatus {
  status?: string;
  built_at?: string | null;
  epc_built_at?: Date | null;
  perspective?: string;

  elements: {
    [key: string]: string[];
  };
}

export type ElementProgressData = {
  total: number;
  actual: number;
  relative_plan: number;
};

export type DateCountData = {
  date: string;
  count: number;
};

export type WBSElementReportData = {
  element: string;
  total_count: number;
  actual_count: DateCountData[];
  planned_count: DateCountData[];
};

type FlagElements = {
  flagged_at: string | Date | null;
  elements: {
    modules: Array<string>;
  };
};

export type Holiday = {
  name: string;
  date: string;
  country: string;
};

export { FlagElements };

@Injectable({
  providedIn: 'root',
})
export class ConstructionGeoService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  public getProjectData(projectUUID?: string): Observable<GeoProjectModel> {
    return this.http
      .get<GeoProjectModel>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/`)
      .pipe(map((res: any) => res.data));
  }

  public getChildWBSEElements(
    wbse?: string,
    parent?: string | Array<string>,
    id?: string,
    page = 1,
    projectUUID?: string,
    pageSize = 15,
  ): Observable<GeoProjectResponse> {
    let qry = {};
    if (typeof parent === 'string') {
      switch (parent) {
        case 'area':
          qry = { ...qry, area_id: id };
          break;
        case 'tracker':
          qry = { ...qry, tracker_id: id };
          break;
        case 'feeder':
          qry = { ...qry, feeder_id: id, page: page };
          break;
        case 'bha':
          qry = { ...qry, bha_id: id };
          break;
        case 'damper':
          qry = { ...qry, damper_id: id };
          break;
        case 'post':
          qry = { ...qry, post_id: id };
          break;
        case 'project':
          qry = { ...qry, project_id: id };
          break;
        case 'slew':
          qry = { ...qry, slew_id: id };
          break;
        case 'torque_tube_id':
          qry = { ...qry, torque_tube_id: id };
          break;
        case 'combiner_boxes':
          qry = { ...qry, combiner_box_id: id };
          break;
        case 'combiner_box':
          qry = { ...qry, combiner_box_id: id };
          break;
        case 'dc_feeder':
          qry = { ...qry, dc_feeder_id: id };
          break;
        case 'pcs':
          qry = { ...qry, pcs_id: id };
          break;
        case 'ac_station':
          qry = { ...qry, ac_station_id: id };
          break;
        case 'mv_feeder':
          qry = { ...qry, mv_feeder_id: id };
          break;
        case 'cbx':
          qry = { ...qry, cbx_id: id };
          break;
        case 'type':
          qry = { ...qry, type_id: id };
          break;
        default:
          qry = { ...qry };
      }
    } else if (parent && typeof parent !== 'string' && parent?.length > 1) {
      parent.map(() => {
        qry = { ...qry, ...{ value_key: id, page: page } };
      });
    }

    return this.apiService.getRemove<GeoProjectResponse>(
      null,
      `geo_service/project/${projectUUID}/${wbse}/`,
      { ...qry, page: page, page_size: pageSize },
      'get',
      false,
    );
  }

  public getSite(projectUUID: string): Observable<AreaModel[]> {
    return this.http
      .get<
        TBResponse<AreaModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/all/sites/`)
      .pipe(map((res) => res.data));
  }

  public getAreas(projectUUID: string): Observable<AreaModel[]> {
    return this.http
      .get<
        TBResponse<AreaModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/all/areas/`)
      .pipe(map((res) => res.data));
  }

  public getMvCircuits(projectUUID: string): Observable<AreaModel[]> {
    return this.http
      .get<
        TBResponse<AreaModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/all/feeders/`)
      .pipe(map((res) => res.data));
  }

  public getMvFeeders(projectUUID: string, pageSize = 100): Observable<MVFeeder[]> {
    let params = new HttpParams();
    params = params.append('page_size', pageSize);

    return this.http
      .get<TBResponse<MVFeeder[]>>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/feeders/`,
        {
          params,
        },
      )
      .pipe(map((res) => res.data));
  }

  public patchFeeder(projectUUID: string, data: UpdatedMVFeederPayload): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/ccc/feeders/`,
      data,
    );
  }

  public getPCSs(projectUUID: string | undefined): Observable<AreaModel[]> {
    return this.http
      .get<
        TBResponse<AreaModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/all/pcs/`)
      .pipe(map((res) => res.data));
  }

  public getTrackerTypes(projectUUID: string): Observable<TrackerTypeModel[]> {
    return this.http
      .get<
        TBResponse<TrackerTypeModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/tracker_type/`)
      .pipe(map((res) => res.data));
  }

  public getTableTypes(projectUUID: string): Observable<TableTypeModel[]> {
    return this.http
      .get<
        TBResponse<TableTypeModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/table_type/`)
      .pipe(map((res) => res.data));
  }

  public getAreaData(projectUUID: string, areaId: string): Observable<TrackerModel> {
    return this.http
      .get<
        TBResponse<TrackerModel>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/areas/${areaId}/`)
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public getModuleTypes(projectUUID: string): Observable<TableTypeModel[]> {
    return this.http
      .get<
        TBResponse<TableTypeModel[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/module_types/`)
      .pipe(
        map((res: any) => {
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getTrackerData(projectUUID: string, trackerId: string): Observable<TrackerModel> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/trackers/${trackerId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.anchor) {
            res.data.anchor.coordinates = this.getCoords(res.data.anchor.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getACStationsData(projectUUID: string, acStationsId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/ac_stations/${acStationsId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getPCSData(projectUUID: string, pcsId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/pcs/${pcsId}/`)
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getMVCircuitData(projectUUID: string, feederId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/feeders/${feederId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getMVFeedersData(projectUUID: string, mvFeedersId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/mv_feeders/${mvFeedersId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getDCFeedersData(projectUUID: string, dcFeedersId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/dc_feeders/${dcFeedersId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getDCHarnessesData(projectUUID: string, dcHarnessesId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/dc_harnesses/${dcHarnessesId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getCombinerBoxes(projectUUID: string, combinerBoxesId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/combiner_boxes/${combinerBoxesId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getCBX(projectUUID: string, cbxId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/cbx/${cbxId}/`)
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getPiersData(projectUUID: string, pierId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/piers/${pierId}/`)
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getHolesData(projectUUID: string, holeId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/holes/${holeId}/`)
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getTablesData(projectUUID: string, tableId: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/tables/${tableId}/`)
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public getModulesData(projectUUID: string, moduleId: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/modules/${moduleId}/`,
      )
      .pipe(
        map((res: any) => {
          if (res.data.center) {
            res.data.center.coordinates = this.getCoords(res.data.center.coordinates);
          }
          res.data.built_at = res.data.built_at ? new Date(res.data.built_at) : undefined;
          res.data.plan_end = res.data.plan_end ? new Date(res.data.plan_end) : undefined;
          res.data.plan_start = res.data.plan_start ? new Date(res.data.plan_start) : undefined;

          return res.data;
        }),
      );
  }

  public updateStatus(data: IUpdateStatus, projectUUID: string, perspective: string): Observable<any> {
    return this.apiService.postPatch(
      `geo_service/project/${projectUUID}/wbselements/status/?perspective=${perspective}`,
      data,
      null,
      'patch',
      true,
    );
  }

  public getProgress(project: ConstructionProjectLoadModel, data: any) {
    return this.http
      .get(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/construction_projects/${data.project_id}/progress`,
        {
          params: data,
        },
      )
      .pipe(
        map((res: any) => {
          const data = res.data;

          const calculatedData = this.calculateProgressValues(project, data);

          return calculatedData;
        }),
      );
  }

  public getDashboardProgress(project: ConstructionProjectLoadModel, refresh = false, from_date = '', to_date = '') {
    return this.http
      .get<
        TBResponse<ProgressRefreshType>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/construction_projects/${project._id}/dashboard_progress/`, { params: { refresh, from_date, to_date } })
      .pipe(
        map((res) => {
          const data = res.data;

          const calculatedData = this.calculateProgressValues(project, data);

          const response = { lastRefreshedDate: data.last_refreshed, calculatedData: calculatedData };

          return response;
        }),
      );
  }

  public getReportData(params: any): Observable<WBSElementReportData> {
    return this.http
      .get(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/construction_projects/${params.id}/element_progress`,
        {
          params,
        },
      )
      .pipe(
        map((res: any) => {
          return { ...res.data, element: params.element };
        }),
      );
  }

  public deleteHoles(geoProjectId: string, ids: string[]): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/holes/delete/`,
      {
        hole_ids: ids,
      },
    );
  }

  public createHolesForSpecificPiers(
    projectID: string,
    data: { is_installed: boolean; pier_ids: Array<string> },
    perspective: string,
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectID}/hole_for_pier/?perspective=${perspective}`,
        data,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public getPreviousWorkDayOfConstructionProject(projectUUID: string, date: Date | string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/workdays/previous/?beginning_date=${date}`,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public validateIfHoleExistsOnPier(projectUUID: string, pierIds: Array<string>): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${projectUUID}/piers/validate_existing_holes/`,
        { pier_ids: pierIds },
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public validateElementInstallation(
    geoProjectId: string,
    date: string | null,
    elements: any,
    perspective: string,
  ): Observable<any> {
    const data = {
      date: date,
      elements: {
        ...elements,
      },
    };

    return this.http.post(
      `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/wbselements/validate/?perspective=${perspective}`,
      data,
    );
  }

  public flagWBSElement(geoProjectId: string, data: FlagElements): Observable<unknown> {
    return this.http.patch(
      `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/wbselements/flag/status/`,
      data,
    );
  }

  public getBessData(geoProjectId: string, bessId: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/bess/${bessId}/`)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public getSubstationData(geoProjectId: string, substationId: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/substations/${substationId}/`,
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
      );
  }

  public getRoadsData(geoProjectId: string, roadId: string): Observable<RoadsResponseData> {
    return this.http
      .get<
        TBResponse<RoadsResponseData>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/roads/${roadId}/`)
      .pipe(
        map((roadsResponse) => {
          return roadsResponse.data;
        }),
      );
  }

  public getFencesData(geoProjectId: string, fenceId: string): Observable<FenceData> {
    return this.http
      .get<
        TBResponse<FenceData>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${geoProjectId}/fences/${fenceId}/`)
      .pipe(
        map((fenceResponse) => {
          return fenceResponse.data;
        }),
      );
  }

  public getHolidays(fromDate: Moment, toDate: Moment): Observable<Holiday[]> {
    const params = {
      country: 'USA',
      from_date: fromDate.format('YYYY-MM-DD'),
      to_date: toDate.format('YYYY-MM-DD'),
    };

    return this.http
      .get<TBResponse<Holiday[]>>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/holidays/`, {
        params,
      })
      .pipe(
        map((roadsResponse) => {
          return roadsResponse.data;
        }),
      );
  }

  public getSiteData(id: string) {
    return this.http
      .get<
        TBResponse<SiteDataType>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/project/${id}/advanced/`)
      .pipe(
        map((siteResponse) => {
          return siteResponse.data;
        }),
      );
  }

  public getLatestInstallationDate(id: string, perspective = 'owner'): Observable<LatestInstallationDateType> {
    return this.http
      .get<
        TBResponse<LatestInstallationDateType>
      >(`${environment.apiUrl}/${environment.apiVersion}/geo_service/construction_projects/${id}/get_last_installation_dates/?perspective=${perspective}`)
      .pipe(
        map((projects) => {
          return projects.data;
        }),
      );
  }

  public getAcBlocksWithObservations(
    geoProjectId: string,
    getObservations = false,
  ): Observable<AcBlockWithObservation[]> {
    return this.http
      .get<
        TBResponse<AcBlockWithObservation[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${geoProjectId}/qc_issues/pcs/?with_issues=${getObservations}`)
      .pipe(map((res) => res.data));
  }

  public sendCSVExportToMail(projectId: string, data: CSVExportDataType[]) {
    return this.http.post<CSVExportDataType>(
      `${environment.apiUrl}/${environment.apiVersion}/geo_service/construction_projects/${projectId}/send_csv_progress/`,
      { reports: data },
    );
  }

  public getProgressElementRequestParams(
    params: ChartDataParams,
    groups: string,
    elements: string[],
  ): ChartDataRequestParams[] {
    const splitGroups = groups.split('=');
    const requestParams: ChartDataRequestParams[] = [];

    const requestParamsGroupType = {
      name: splitGroups[0] as ChartDataRequestParamEnum,
      value: splitGroups[1],
    };

    elements.forEach((element: string) => {
      requestParams.push({ ...params, groupType: requestParamsGroupType, element: element });
    });

    return requestParams;
  }

  public formatElementProgressRequestParams(params: ChartDataRequestParams[]) {
    const formatedParams = params.map((param) => {
      return {
        element: param.element,
        from_date: param.from_date,
        to_date: param.to_date,
        perspective: param.perspective,
        [param.groupType.name]: param.groupType.value,
        id: param.id,
        time_granulation: param.time_granulation,
        timezone: param.timezone,
      };
    });

    return formatedParams;
  }

  private getCoords(pointArr: Array<number>, decimals = 8): Array<string> | undefined {
    return pointArr ? pointArr.map((x) => Number(x).toFixed(decimals)) : undefined;
  }

  private calculateValues(project: ConstructionProjectLoadModel, wbseName: string, data: any) {
    const item = data[wbseName];

    const weight = project.weights && wbseName in project.weights ? project.weights[wbseName] : 0;
    const actual = this.calculateAbsPct(item.actual, item.total) * 100;
    const plan =
      wbseName === MapLayerEnum.SUBSTATIONS
        ? item.relative_plan || 0
        : this.calculateAbsPct(item.relative_plan, item.total) * 100;

    return {
      wbsElement: wbseName,
      type: wbseName,
      actual: actual,
      plan: plan,
      delta: actual - plan,
      weight: weight,
    };
  }

  private calculateAbsPct(x: number, tot: number) {
    return tot ? (x || 0) / tot : 0;
  }

  private calculateProgressValues(project: ConstructionProjectLoadModel, data: ProgressRefreshType) {
    return [
      this.calculateValues(project, MapLayerEnum.ROADS, data),
      this.calculateValues(project, MapLayerEnum.FENCES, data),
      this.calculateValues(project, MapLayerEnum.HOLES, data),
      this.calculateValues(project, MapLayerEnum.PIERS, data),
      this.calculateValues(project, MapLayerEnum.TRACKERS, data),
      this.calculateValues(project, MapLayerEnum.TABLES, data),
      this.calculateValues(project, MapLayerEnum.MODULES, data),
      this.calculateValues(project, MapLayerEnum.CBX, data),
      this.calculateValues(project, MapLayerEnum.DC_HARNESSES, data),
      this.calculateValues(project, MapLayerEnum.DC_FEEDERS, data),
      this.calculateValues(project, MapLayerEnum.AC_STATIONS, data),
      this.calculateValues(project, MapLayerEnum.MV_FEEDERS, data),
      this.calculateValues(project, MapLayerEnum.BESS, data),
      this.calculateValues(project, MapLayerEnum.SUBSTATIONS, data),
    ];
  }
}
