import { IEnvironmentConfig } from './environment.type';

export const environment: IEnvironmentConfig = {
  name: 'development-test',
  production: false,
  apiUrl: 'https://test-api.dev-clearsky.terabase.services/api',
  arcGisAPIkey: 'AAPK10f6470145564c2eb865ff557f651638YA3Wq9kwCsHA3BsaYDkzVuID_3Pc_F0lAkpyhucjZF6P8tF3RtepnqtoRN--Lj7p',
  esriToken:
    'fz8REc2V3Pf4KyAHPowgrKSNy-WlCQOuDkM7BMr1EX2nqITr2NTeu2D8DE3X7Z-qsWG8iOKaAxnBKf4WTLaOcsGfRkUTPtkjH9ZOi78IPfq4JSK66EtbKsn3C1fgPR66f7GxcuPey3Pbi8QfLH58yw..',
  apiVersion: 'v1',
  tokenRefreshBeforeExpTime: 60, // seconds
  geoserverHost: 'https://test-geoserver-1.dev-clearsky.terabase.services:8443',
  s3ProxyUrl: 'https://cog-proxy.dev-clearsky.terabase.services/tiles/{z}/{x}/{y}',
  accuWeatherApiKey: 'EG90JJOkl6eL19855yaMJxM0bKlv41hV',
  agGridLicenseKey:
    'Using_this_AG_Grid_Enterprise_key_( AG-046841 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Terabase Energy Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Terabase Construct )_only_for_( 8 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Terabase Construct )_need_to_be_licensed___( Terabase Construct )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 October 2024 )____[v2]_MTcyOTI5MjQwMDAwMA==3f7df7c255537821843ec4303136674d',

  // Cognito Amplify config
  awsConfig: {
    auth: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_36Ud7LfLL',
      userPoolWebClientId: '409jlg5csi4fec64tn97e18d97',
      identityPoolId: 'us-west-2:879e0dc5-8663-43ae-a1fd-00787a704edd',

      // Hosted UI configuration
      oauth: {
        domain: 'terabase-dev.auth.us-west-2.amazoncognito.com',
        scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: ['https://test-www.dev-clearsky.terabase.services/'],
        redirectSignOut: ['https://test-www.dev-clearsky.terabase.services/'],
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
      cookieStorage: {
        domain: 'terabase.services', // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        path: '/', // OPTIONAL - Cookie path
        expires: 365, // OPTIONAL - Cookie expiration in days
        sameSite: 'lax', // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        secure: true, // OPTIONAL - Cookie secure flag. Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      },
    },
  },
  googleAnaliticsConfig: {
    measurementId: 'G-MKKGLC3ZYG',
  },
  featuresList: {
    advance_detection: true,
    adding_observation: true,
    local_mode: true,
    inspections: true,
    draft_observation: true,
    digital_twin_configuration: true,
  },
  lightGalleryLK: 'F52A8933-4624-4044-A73C-431171277864',
};
