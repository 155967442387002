import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CompanyInterface, CompanyModel } from '../../interfaces/company.interface';
import { InvitationSentDataType, UserInterface } from '../../interfaces/user.interface';
import { Pagination } from '@core/models/project.model';
import { TBResponse } from '@construction/models/wbs-elements.model';

interface CompanyQueryOptions {
  page?: number;
  pageSize?: number;
  isBuyer?: boolean;
  isSupplier?: boolean;
  isConstructionEpc?: boolean;
  isConstructionOwner?: boolean;
  isSupplierCompany?: boolean;
  disabled?: boolean;
}

export type SendInvitesBodyType = {
  company_id: string;
  email_signature: string;
  email_body: string;
  recipients: string[];
  email_subject: string;
};

export type FailedMailsType = {
  failed_emails: string[];
};

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public companies: Observable<any>;
  public company: Observable<any>;

  private companiesSubject: BehaviorSubject<any>;
  private companySubject: BehaviorSubject<any>;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {
    this.companiesSubject = new BehaviorSubject<any>(null);
    this.companies = this.companiesSubject.asObservable();

    this.companySubject = new BehaviorSubject<any>(null);
    this.company = this.companySubject.asObservable();
  }

  /**
   * Function for getting array of companies
   *
   * @return Observable<CompanyInterface>
   */
  public getCompanies(options: CompanyQueryOptions = {}) {
    const {
      page = 1,
      pageSize = 15,
      isBuyer,
      isSupplier,
      isConstructionEpc,
      isConstructionOwner,
      isSupplierCompany,
      disabled,
    } = options;
    let params = new HttpParams().set('page', page).set('page_size', pageSize);

    if (isBuyer !== undefined) {
      params = params.set('is_buyer', isBuyer);
    }
    if (isSupplier !== undefined) {
      params = params.set('is_supplier', isSupplier);
    }
    if (isConstructionEpc !== undefined) {
      params = params.set('is_construction_epc', isConstructionEpc);
    }
    if (isConstructionOwner !== undefined) {
      params = params.set('is_construction_owner', isConstructionOwner);
    }
    if (isSupplierCompany !== undefined) {
      params = params.set('is_supplier_company', isSupplierCompany);
    }
    if (disabled !== undefined) {
      params = params.set('disabled', disabled);
    }

    return this.http
      .get<{
        data: CompanyModel[];
        pagination: Pagination;
      }>(`${environment.apiUrl}/${environment.apiVersion}/companies/`, { params })
      .pipe(tap((companies) => this.companiesSubject.next(companies)));
  }

  /**
   * //TODO: Get company for specific user
   * Function for getting company details
   *
   * @param: id: string
   * @return Observable<CompanyInterface>
   */
  public getSingleCompany(id: string | null | undefined): Observable<CompanyInterface> {
    return this.apiService.getRemove<CompanyInterface>(Number(id), `companies/${id}/`, {}, 'get', false).pipe(
      tap((company) => {
        const comp = company.data;
        this.companySubject.next(comp);
      }),
    );
  }

  /**
   * //TODO: Backend connection
   * Function for getting company success-bid grade
   *
   * @param: id: string
   * @return Array<object>
   */
  public getCompanySuccessGrade(id: string | null): Observable<any> {
    return this.apiService.getRemove<Array<any>>(Number(id), 'successGrade', {}, 'get', false);
  }

  /**
   * //TODO: Backend connection
   * Function for getting company number of finalized deals
   *
   * @param: id: string
   * @return Array<object>
   */
  public getCompanyFinalizedDeals(id: string | null): Observable<any> {
    return this.apiService.getRemove<Array<Record<string, unknown>>>(Number(id), 'finalizedDeals', {}, 'get', false);
  }

  /**
   * Function for creating company details
   *
   * @param: data: CompanyInterface
   * @return CompanyInterface
   */
  public createCompany(data: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiVersion}/companies/`, data).pipe(
      tap((company: any) => {
        const comp = company.data;
        this.companySubject.next(comp);
      }),
    );
  }

  /**
   * Function for updating company details
   *
   * @param: data: CompanyInterface
   * @return CompanyInterface
   */
  public updateCompany(id: any, data: FormData): Observable<CompanyInterface> {
    return this.apiService
      .postPatch(`companies/${id}/`, data, null, 'patch', false, {})
      .pipe(switchMap(() => this.getSingleCompany(id)));
  }

  /**
   * //TODO: Backend connection
   * Function for deleting Image for specific company
   *
   * @param: id: string
   * @return object
   */
  public deleteImage(route: string, id: string | null | undefined): Observable<CompanyInterface> {
    return this.apiService.postPatch(route, { id, image: undefined }, Number(id), 'put', false, {});
  }

  /**
   * //TODO: Backend connection
   * Function for updating base64 image for company
   *
   * @params: image: string, id: string
   * @return object
   */
  public updateImage(
    route: string,
    image: string | null | undefined,
    id: string | null | undefined,
  ): Observable<CompanyInterface> {
    return this.apiService.postPatch(route, { image }, Number(id), 'put', false, {});
  }

  public getCompanyContacts(data?: any): Observable<any> {
    const company = this.companySubject.value;
    const companyId = company?._id || data.id;

    return companyId
      ? this.http.get(
          `${environment.apiUrl}/${environment.apiVersion}/companies/${
            company !== null ? company._id : data.id
          }/users/?page=${data.page || 1}&page_size=${data.page_size || 100}`,
        )
      : of([]);
  }

  public getCompanyUsers(
    companyId: string,
    page?: number,
    pageSize?: number,
  ): Observable<{ data: UserInterface[]; pagination: Pagination }> {
    return this.http.get<{ data: UserInterface[]; pagination: Pagination }>(
      `${environment.apiUrl}/${environment.apiVersion}/companies/${companyId}/users/?page=${page || 1}&page_size=${
        pageSize || 100
      }`,
    );
  }

  public setCompany(company: any) {
    this.companySubject.next(company);
  }

  public getInvitations(): Observable<{ data: InvitationSentDataType[]; pagination: Pagination }> {
    const company = this.companySubject.value;

    return this.http.get<{ data: InvitationSentDataType[]; pagination: Pagination }>(
      `${environment.apiUrl}/${environment.apiVersion}/companies/${company._id}/invitations/`,
    );
  }

  public deleteInvitation(id: any) {
    return this.http.delete(`${environment.apiUrl}/${environment.apiVersion}/invitations/${id}/`);
  }

  public sendInvitations(data: SendInvitesBodyType) {
    return this.http
      .post<TBResponse<FailedMailsType>>(`${environment.apiUrl}/${environment.apiVersion}/invitations/`, data)
      .pipe(
        map((response) => {
          return response.data;
        }),
      );
  }
}
