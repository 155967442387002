import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserRole, UserService } from 'src/app/@core/services/rest/user.service';
import { AuthenticationService } from '../../../@core/services/rest/authentication.service';
import { ProjectService } from '../../../@core/services/common/project.service';
import { forkJoin } from 'rxjs';

import { SubSink } from 'subsink';
import { UserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog.component';
import { UserInterface } from 'src/app/@core/interfaces/user.interface';
import { ConstructionProjectsService } from '@construction/services/construction-project.service';
import { ConstructionProjectList } from '@construction/models/construction-project.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  public userName = '';
  public profilePicture?: string;
  public pinnedProjects: ConstructionProjectList[] = [];
  public unpinnedProjects: ConstructionProjectList[] = [];
  public userRole = '';
  public userRoleEnum = UserRole;
  public userData: UserInterface;
  private subs = new SubSink();

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthenticationService,
    private constructionProjectsService: ConstructionProjectsService,
  ) {}

  public ngOnInit(): void {
    this.subs.sink = this.userService.currentUser.subscribe((user) => {
      this.userName = `${user.data.contact_profile?.first_name} ${user.data.contact_profile?.last_name}`;
      this.profilePicture = user.data.contact_profile?.profile_picture?._id;
      this.userRole = user.data.businessRole || user.data.userRole;
      this.userData = user.data;
    });

    this.refreshProjects();
  }

  public logout(): void {
    this.authService.logOut();
  }

  public onPinProject(selectedProject: ConstructionProjectList, pinned: boolean): void {
    this.subs.sink = this.projectService.pinProject(selectedProject._id, pinned).subscribe({
      next: () => {
        if (pinned) {
          this.pinnedProjects.push(selectedProject);
          this.unpinnedProjects = this.unpinnedProjects.filter((project) => project._id !== selectedProject._id);
        } else {
          this.unpinnedProjects.push(selectedProject);
          this.pinnedProjects = this.pinnedProjects.filter((project) => project._id !== selectedProject._id);
        }
      },
      error: console.error,
    });
  }

  public openUsersProfile(): void {
    this.dialog.open(UserProfileDialogComponent);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private refreshProjects() {
    this.subs.sink = forkJoin([
      this.constructionProjectsService.getConstructionProjects(1, 1000),
      this.projectService.getPinnedProjects(this.userService.user._id),
    ]).subscribe(([projects, pinned]) => {
      const projectsWithUrl = projects.data.map((project) => {
        return {
          ...project,
          url: this.projectService.createProjectUrl(project._id, project.status),
        };
      });

      const pinnedIdsMap = pinned.data.reduce((map, pinnedProject) => {
        map.set(pinnedProject.construction_project_id, true);

        return map;
      }, new Map());

      const { pinnedProjects, unpinnedProjects } = projectsWithUrl.reduce(
        (acc, project) => {
          const foundInPinned = pinnedIdsMap.has(project._id);

          if (foundInPinned) {
            acc.pinnedProjects.push(project);
          } else {
            acc.unpinnedProjects.push(project);
          }

          return acc;
        },
        {
          pinnedProjects: [] as ConstructionProjectList[],
          unpinnedProjects: [] as ConstructionProjectList[],
        },
      );

      this.pinnedProjects = pinnedProjects;
      this.unpinnedProjects = unpinnedProjects;
    });
  }
}
