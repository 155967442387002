import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserInterface } from '../../interfaces/user.interface';
import { CodingListItem } from '../resolvers/profile.resolver';
import { Pagination } from '@core/models/project.model';

export enum UserRole {
  SuperAdmin = 'superadmin',
  CompanyAdmin = 'company_admin',
  CompanyUser = 'company_user',
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: UserInterface;
  public currentUser: Observable<{ data: UserInterface }>;
  public isSuperAdmin$: Observable<boolean>;
  public isCompanyAdmin$: Observable<boolean>;
  public isCompanyUser$: Observable<boolean>;

  private currentUserSubject = new ReplaySubject<{ data: UserInterface }>();

  constructor(private http: HttpClient) {
    this.currentUser = this.currentUserSubject.asObservable();
    this.isSuperAdmin$ = this.currentUser.pipe(map((user) => user?.data.userRole === UserRole.SuperAdmin));
    this.isCompanyAdmin$ = this.currentUser.pipe(map((user) => user?.data.userRole === UserRole.CompanyAdmin));
    this.isCompanyUser$ = this.currentUser.pipe(map((user) => user?.data.userRole === UserRole.CompanyUser));
  }

  public isSuperAdmin(): boolean {
    return this.user?.userRole === UserRole.SuperAdmin;
  }

  public isCompanyAdmin(): boolean {
    return this.user?.userRole === UserRole.CompanyAdmin;
  }

  public isCompanyUser(): boolean {
    return this.user?.userRole === UserRole.CompanyUser;
  }

  public isEpcCompanyAdmin(): boolean {
    return (
      (this.user?.userRole === UserRole.CompanyAdmin || this.user?.userRole === UserRole.CompanyUser) &&
      this.user.company_profile?.is_construction_epc
    );
  }

  public setCurrentUser(userData: { data: UserInterface }): void {
    this.currentUserSubject.next(userData);
  }

  public fetchCurrentUser(): Observable<{ data: UserInterface }> {
    return this.http.get<{ data: UserInterface }>(`${environment.apiUrl}/${environment.apiVersion}/user/`);
  }

  public updateUser(formData: FormData, userId: string) {
    return this.http.patch(`${environment.apiUrl}/${environment.apiVersion}/users/${userId}/`, formData);
  }

  public getInvitation(code: string) {
    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/public/invitations/${code}/`);
  }

  public getInvitations() {
    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/invitations/`);
  }

  public getUsers() {
    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/users/`);
  }

  public getCodingLists() {
    const salesRegionObs = this.http.get<{ data: CodingListItem[]; pagination: Pagination }>(
      `${environment.apiUrl}/${environment.apiVersion}/user_sales_region_coding_list/`
    );
    const titleCodingListObs = this.http.get<{ data: CodingListItem[]; pagination: Pagination }>(
      `${environment.apiUrl}/${environment.apiVersion}/user_title_coding_list/`
    );

    return forkJoin([salesRegionObs, titleCodingListObs]).pipe(
      map(([regions, titles]) => {
        return { sales_region_coding_list: regions, title_coding_list: titles };
      })
    );
  }

  public changeUserAvailability(user: UserInterface) {
    return this.http.put(`${environment.apiUrl}/${environment.apiVersion}/users/${user._id}/change_enablement/`, {
      disabled: !user.disabled,
    });
  }

  public addKeyAccountManager(profileId: string) {
    return this.http.post(`${environment.apiUrl}/${environment.apiVersion}/key_account_managers/${profileId}/add/`, {});
  }

  public removeKeyAccountManager(profileId: string) {
    return this.http.post(
      `${environment.apiUrl}/${environment.apiVersion}/key_account_managers/${profileId}/remove/`,
      {}
    );
  }
}
